import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useOutletContext, Outlet } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";

import { api } from "../../../utils/Api";
import { LazyLoader, ImageLazyLoader } from "../../../utils/LazyLoader/index";
import { AppConfig } from "../../../config";
import Skeleton from "react-loading-skeleton";
import ModalTime from "./ModalTime";



export default function Statistics(props: any) {
    const [statistics, SetStatistics]: any = useState({});
  const context: any = useContext(DataContext);
  const [time_modal, setTimeModal] = useState({
    is_open: false,
    data: {},
  });
    const [statisticsView, SetStatisticsView]: any = useState('custom');
    const [statisticsViewFormatted, SetStatisticsViewFormatted]: any = useState('Custom');
    const [urlParams, setUrlParams] = useState(useParams());
    const [startDate, setStartDate] = useState("01-06-2024");
    const [endDate, setEndDate] = useState(convertDateTime());
    const [startDateShow, setStartDateShow] = useState("");
    const [endDateShow, setEndDateShow] = useState("");

    function convertDateTime(dateTimeStr = '') {
        const date = dateTimeStr != ''?new Date(dateTimeStr):new Date();
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
        const year = date.getFullYear();
        
        // const hours = String(date.getHours()).padStart(2, '0');
        // const minutes = String(date.getMinutes()).padStart(2, '0');
        // const seconds = '00'; // Set seconds to 00
        
        const formattedDateTime = `${day}-${month}-${year} `;
        return formattedDateTime;
      }
  

    useEffect(() => {
        get_statistics();
        if(statisticsView == 'today'){
            SetStatisticsViewFormatted('Today')
        } else if(statisticsView == 'yesterday'){
            SetStatisticsViewFormatted('Yesterday')
        } else if(statisticsView == 'lastweek'){
            SetStatisticsViewFormatted('Last Week')
        }else if(statisticsView == 'week'){
            SetStatisticsViewFormatted('This Week')
        } else if(statisticsView == 'pastweek'){
            SetStatisticsViewFormatted('Past Week')
        }else if(statisticsView == 'pastmonth'){
            SetStatisticsViewFormatted('Past Month')
        } else if(statisticsView == 'lastmonth'){
            SetStatisticsViewFormatted('Last Month')
        }else if(statisticsView == 'month'){
            SetStatisticsViewFormatted('This Month')
        } else if(statisticsView == 'year'){
            SetStatisticsViewFormatted('This Year')
        }else if(statisticsView == 'lastyear'){
            SetStatisticsViewFormatted('Last Year')
        } else if(statisticsView == 'finyear'){
            SetStatisticsViewFormatted('This Financial Year')
        }else if(statisticsView == 'lastfinyear'){
            SetStatisticsViewFormatted('Last Financial Year')
        } else if(statisticsView == 'custom'){
            SetStatisticsViewFormatted('Custom')
        }
    }, [statisticsView]);

    async function get_statistics() {
        let pass_data:any = {
            get: {
                brand: urlParams.brand_id,
                outlet: urlParams.outlet_id,
                view: statisticsView,
            },
        };
        if(startDate){
            pass_data.get.from = startDate;
        }
        if(endDate){
            pass_data.get.to = endDate;
        }
        let data: any = await api("/outlet/statistics", pass_data);
        SetStatistics(data.response?.data);
        setStartDate(data.response?.start)
        setEndDate(data.response?.end)
        const convertedDateTime = convertDateTime(data.response?.start_display);

           

      
        const convertedEndDateTime = convertDateTime(data.response?.end_display);
        setStartDateShow(convertedDateTime)
        setEndDateShow(convertedEndDateTime)

    }

    return (
        <div className="row font-noto-sans">
            <div className="col-12">
                   {( context.app_data.pages[20] || context.app_data.pages[21]) && 
                <div className="d-flex align-items-center mb-2">
                    <div>
                        <h6 className="mb-0">Overview</h6>
                    </div>
                    <div className="ms-auto">
                    <div className="d-flex align-items-center border br-1 pr-2 bg-white">
                        <div className="dropdown">
                            <button className="btn bg-white text-muted btn-sm br-4 px-2 d-flex dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <ImageLazyLoader className="img-fluid" width={10} src={AppConfig.CDN_Media_url+"420485dfc5e7bf2426a897a723ad243c.svg"} />
                                <span className="ps-1">{statisticsViewFormatted}</span>
                            </button>
                            <ul className="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('today'); }}>Today</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('yesterday'); }}>Yesterday</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastweek'); }}>Last Week</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('week'); }}>This Week</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastweek'); }}>Past Week</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastmonth'); }}>Past Month</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastmonth'); }}>Last Month</button></li>
                                
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('month'); }}>This Month</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('year'); }}>This Year</button></li>


                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastyear'); }}>Last Year</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('finyear'); }}>This Financial Year</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastfinyear'); }}>Last Financial Year</button></li>
                                <li><button type="button" className="dropdown-item"
                                 onClick={() => {    let set_data = {
                                    start:startDate,
                                    end:endDate
                                       
                                        
                                      
                                        
                                      };
                                      setTimeModal({
                                        is_open: true,
                                        data: set_data,
                                      });}}>Custom</button></li>
                                
                            </ul>
                        </div>
                        <div className="text-secondary  me-2 fs-12 cursor pr-1" 
                              onClick={() => {
                                let set_data = {
                                start:startDate,
                                end:endDate,
                                   
                                    from:"outlet"
                                  
                                    
                                  };
                                  setTimeModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                        // data-toggle="modal" data-target="#customDate"
                        >{startDateShow == endDateShow ? startDateShow : startDateShow+' - '+endDateShow}</div>
                    </div>
                    </div>

                </div>
}
            </div>
            {context.app_data.pages[21] && 
            <div className="col-lg-4 col-6 pe-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/330ac397130f29b354ca21fa5d8fdead.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('completed_order_amount') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('completed_order_amount') && (
                            <>&#8377;{statistics?.completed_order_amount}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Completed Order Amount</div>
                </div>
            </div>
}

{context.app_data.pages[20] && 
            <div className="col-lg-4 col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/c9264ab9318ea975cc885c7f8e46d8a5.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('settlement_eligible_amount') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('settlement_eligible_amount') && (
                            <>&#8377;{statistics?.settlement_eligible_amount}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Settlement Eligible Amount</div>
                </div>
            </div>
}
{context.app_data.pages[20] && 
            <div className="col-lg-4 col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/519217af0f1e55dc6e26c23a6a0fc941.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('settlement_paid_amount') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('settlement_paid_amount') && (
                            <>&#8377;{statistics?.settlement_paid_amount}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Settlement Paid Amount</div>
                </div>
            </div>
}
{context.app_data.pages[21] && 

            <div className="col-lg-3 col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/e96c21135fb8ef29dc074ef339f23918.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('completed_orders') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('completed_orders') && (
                            <>{statistics?.completed_orders}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Completed Orders</div>
                </div>
            </div>
}

{context.app_data.pages.hasOwnProperty('20')?
context.app_data.pages[20] && 
            <div className="col-lg-3 col-6 pe-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/b114df5ddb00fff5c3c72f3641cb3076.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('cancelled_orders') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('cancelled_orders') && (
                            <>{statistics?.cancelled_orders}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Cancelled orders</div>
                </div>
            </div> : 
              // skeleton
              [1, 2].map((ele) => (
                <div key={ele} className="col-md-6 col-lg-4">
                  <div className="card p-3 mb-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Skeleton height={10} width={160} />
                        <div className="mt-2">
                          <Skeleton height={10} width={60} />
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              ))}

{context.app_data.pages[20] && 
            <div className="col-lg-3 col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/b6dc54a45f56cbb1e2b261474c529a52.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('rejected_orders') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('rejected_orders') && (
                            <>{statistics?.rejected_orders}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Rejected orders</div>
                </div>
            </div>
}

{context.app_data.pages[20] && 
            <div className="col-lg-3 col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/9fdc9b6a6552a3f31f8f1d11b102f3b2.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('settlement_eligible_orders') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('settlement_eligible_orders') && (
                            <>{statistics?.settlement_eligible_orders}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Settlement Eligible Orders</div>
                </div>
            </div>
}
{context.app_data.pages[20] && 
            <div className="col-lg-3 col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/a8c79cb4ab64f239c834101da15a7862.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('settlement_paid_orders') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('settlement_paid_orders') && (
                            <>{statistics?.settlement_paid_orders}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Settlement Paid Orders</div>
                </div>
            </div>
}
{statistics != null &&
<ModalTime
        open={time_modal.is_open}
        data={time_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setTimeModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            SetStatistics(data.data?.data);
         
   
            setStartDate(data.data?.start)
            setEndDate(data.data?.end)
            SetStatisticsViewFormatted("custom")
            const convertedDateTime = convertDateTime(data.data?.start_display);

           

      
            const convertedEndDateTime = convertDateTime(data.data?.end_display);
            setStartDateShow(convertedDateTime)
            setEndDateShow(convertedEndDateTime)

            
          
     
          }
        }}
      />
    }
        </div>
    );
};
